const clients = [
    {
        id: 3,
        system: 'FAN',
        module: 'ZNAP_MASTER_DATA',
        modulePath: 'masterdata', // 'masterdata' || 'homol.masterdata'
        language: 'pt',
        port: 8082,
        theme: 'fan.js',
        env: 'prod' // 'prod' || 'homol'
    }
]

const getClientConfigs = (clientId) => clients.find(client => client.id === clientId)
const configs = getClientConfigs(3)

export default {
    ...configs
}
